import {
    RouterAfterItemType as SourceRouterAfterItemType,
    RouterBeforeItemType as SourceRouterBeforeItemType,
    RouterSwitchItemType as SourceRouterSwitchItemType
} from 'SourceComponent/Router/Router.config';

export * from 'SourceComponent/Router/Router.config';

export const RouterAfterItemType = {
    ...SourceRouterAfterItemType,
    MULTIPLE_WISHLIST_POPUP: 'MULTIPLE_WISHLIST_POPUP',
};
export const RouterBeforeItemType = {
    ...SourceRouterBeforeItemType,
    SALABLE_QTY_ERROR_POPUP: 'SALABLE_QTY_ERROR_POPUP',
    MEDICAL_CONSENT_POPUP: 'MEDICAL_CONSENT_POPUP',
};

export const RouterSwitchItemType = {
    ...SourceRouterSwitchItemType,
    NEW_PRODUCTS: 'NEW_PRODUCTS',
    BESTSELLERS: 'BESTSELLERS',
    MY_INVOICES: 'MY_INVOICES',
    MY_FREQUENTLY_BOUGHT_ITEMS: 'MY_FREQUENTLY_BOUGHT_ITEMS',
    LOGIN_AS_CUSTOMER: 'LOGIN_AS_CUSTOMER',
    MY_ACCOUNT_WISHLIST_VIEW: 'MY_ACCOUNT_WISHLIST_VIEW'
};
