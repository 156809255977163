/* eslint-disable fp/no-let */
import { ProductType } from 'Component/Product/Product.config';
import { encodeBase64 } from 'Util/Base64';

import { ADD_TO_CART } from './Product';

export * from 'SourceUtil/Product/Transform';

/** @namespace Satisfly/Util/Product/Transform/magentoProductTransform */
export const magentoProductTransform = (
    action = ADD_TO_CART,
    product,
    quantity = 1,
    enteredOptions = [],
    selectedOptions = [],
    isPackageProduct = false,
) => {
    const {
        id, sku = '', type_id: typeId, package: productPackage,
    } = product;

    const productData = [];

    if (typeId === ProductType.GROUPED && action === ADD_TO_CART) {
        const { items = [] } = product;
        const groupedProducts = [];

        if (!isPackageProduct) {
            let isQtySetInGroupedProducts = false;
            (items).forEach(({ product: { id } }) => {
                const { [String(id)]: groupedQuantity = 0 } = quantity;

                if (groupedQuantity > 0) {
                    isQtySetInGroupedProducts = true;
                }

                groupedProducts.push(encodeBase64(`grouped/${id}/${groupedQuantity}`));
            });

            if (isQtySetInGroupedProducts) {
                productData.push({
                    id,
                    sku,
                    quantity: 1,
                    ...(isPackageProduct ? {
                        package_price: productPackage[0].final_price_excl_tax.amount.value,
                    } : {}),
                    selected_options: [...selectedOptions, ...groupedProducts],
                    entered_options: enteredOptions,
                });
            }
        } else {
            (items).forEach(({ product: { id, package: productPackage, sku: pSku } }) => {
                const { [String(id)]: groupedQuantity = 0 } = quantity;

                if (groupedQuantity > 0) {
                    productData.push({
                        id,
                        sku: pSku,
                        quantity: groupedQuantity,
                        package_quantity: groupedQuantity,
                        package_price: productPackage[0].final_price_excl_tax.amount.value,
                        selected_options: Array.from(selectedOptions),
                        entered_options: enteredOptions,
                    });
                }
            });
        }
    } else {
        const baseProductToAdd = {
            id,
            sku,
            quantity,
            ...(isPackageProduct ? {
                package_price: productPackage[0].final_price_excl_tax.amount.value,
            } : {}),
            selected_options: selectedOptions,
            entered_options: enteredOptions,
        };

        productData.push(baseProductToAdd);
    }

    return productData;
};
