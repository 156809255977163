/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import { mapStateToProps, RouterContainer as SourceRouterContainer } from 'SourceComponent/Router/Router.container';
import { updateConfigDevice } from 'Store/Config/Config.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

export { mapStateToProps };

/** @namespace Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    updateConfigDevice: (device) => dispatch(updateConfigDevice(device)),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    init: () => waitForPriorityLoad().then(
        /** @namespace Component/Router/Container/mapDispatchToProps/waitForPriorityLoad/then */
        () => {
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/Config/Config.dispatcher'
            ).then(({ default: dispatcher }) => dispatcher.handleData(dispatch, undefined));
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/Wishlist/Wishlist.dispatcher'
            ).then(({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch));
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/MultipleWishlist/MultipleWishlist.dispatcher'
            ).then(({ default: dispatcher }) => dispatcher.updateMultipleWishlistData(dispatch));
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/MyAccount/MyAccount.dispatcher'
            ).then(({ default: dispatcher }) => dispatcher.handleCustomerDataOnInit(dispatch));
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/Cart/Cart.dispatcher'
            ).then(({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch));
        },
    ),
});

/** @namespace Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {

}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
