// eslint-disable-next-line simple-import-sort/imports
import {
    ADD_WISHLIST, DELETE_WISHLIST,
    UPDATE_WISHLIST, UPDATE_WISHLISTS, UPDATE_WISHLIST_NAME
} from './MultipleWishlist.action';

/** @namespace Satisfly/Store/MultipleWishlist/Reducer/getInitialState */
export const getInitialState = () => ({
    wishlists: []
});

/** @namespace Satisfly/Store/MultipleWishlist/Reducer/MultipleWishlistReducer */
export const MultipleWishlistReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case ADD_WISHLIST:
        const { wishlist: newItem } = action;

        return {
            ...state,
            wishlists: [
                { ...newItem, items: [] },
                ...state.wishlists
            ]
        };
    case DELETE_WISHLIST:
        const { wishlistId } = action;

        return {
            ...state,
            wishlists: state.wishlists.filter(({ wishlist_id }) => `${wishlist_id}` !== wishlistId)
        };
    case UPDATE_WISHLIST:
        const { wishlist: newWishlist } = action;

        const newWishlists = state.wishlists.map((wishlist) => (wishlist.wishlist_id === newWishlist.wishlist_id ? newWishlist : wishlist));

        return { ...state, wishlists: newWishlists };

    case UPDATE_WISHLIST_NAME:
        const { id: editedId, name } = action;

        return {
            ...state,
            wishlists: state.wishlists.map((wishlist) => (`${wishlist.wishlist_id}` === editedId ? { ...wishlist, name } : wishlist))
        };

    case UPDATE_WISHLISTS:
        const { wishlists } = action;

        return {
            ...state,
            wishlists
        };

    default:
        return state;
    }
};

export default MultipleWishlistReducer;
